import React, { useEffect, useState } from "react";

const AboutMe = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://parmazing.net/wp-json/wp/v2/pages?slug=aboutme")
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0) setPageData(data[0]);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-yellow-600 font-semibold animate-pulse text-lg">
          Loading...
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-600 font-semibold text-lg">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center px-6 md:px-12 pt-32 pb-20 bg-gray-100">
      {/* Background Accents */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="w-96 h-96 bg-yellow-200 opacity-30 rounded-full absolute top-10 left-10 transform -translate-x-1/3 -translate-y-1/3"></div>
        <div className="w-72 h-72 bg-yellow-100 opacity-30 rounded-full absolute bottom-10 right-10 transform translate-x-1/3 translate-y-1/3"></div>
      </div>

      {/* Content Container */}
      <div className="relative max-w-4xl mx-auto flex flex-col md:flex-row items-center gap-12">
        {/* Image Section */}
        <div className="w-80 h-80 flex-shrink-0">
          <img
            src="https://parmazing.net/wp-content/uploads/img_1482.jpg"
            alt="About Me"
            className="w-full h-full object-cover rounded-3xl shadow-lg transform hover:scale-105 transition duration-300"
          />
        </div>

        {/* Text Section */}
        <div className="max-w-xl">
          <h1 className="text-6xl font-extrabold text-gray-900 leading-tight">
            About <span className="text-yellow-600">parmazing.</span>
          </h1>
          <p className="text-gray-700 text-lg mt-4">
            {pageData?.content?.rendered ? (
              <span dangerouslySetInnerHTML={{ __html: pageData.content.rendered }} />
            ) : (
              "I am a digital marketer and creative storyteller, passionate about exploring visuals, words, and moments."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
