import React from "react";
import { Link } from "react-router-dom"; 
import { Facebook, Twitter, Instagram, Linkedin } from "react-feather"; 

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-10">
      <div className="max-w-6xl mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Logo & Branding */}
          <div className="text-center md:text-left mb-6 md:mb-0">
            <Link to="/" className="text-2xl font-bold text-white">
              parmazing<span className="text-yellow-500">.</span>
            </Link>
            <p className="text-sm text-gray-400 mt-2">Inspiring Digital Marketing & Creativity</p>
          </div>

          {/* Navigation Links
          <div className="flex space-x-6">
            <FooterLink to="/" label="Home" />
            <FooterLink to="/about" label="About" />
            <FooterLink to="/articles" label="Articles" />
            <FooterLink to="/paintings" label="Paintings" />
            <FooterLink to="/quizzes" label="Quizzes" />
            <FooterLink to="/contact" label="Contact" />
          </div> */}

          {/* Social Media Links */}
          <div className="flex space-x-4 mt-6 md:mt-0">
            <SocialLink href="https://facebook.com" icon={<Facebook size={20} />} />
            <SocialLink href="https://twitter.com" icon={<Twitter size={20} />} />
            <SocialLink href="https://instagram.com" icon={<Instagram size={20} />} />
            <SocialLink href="https://linkedin.com" icon={<Linkedin size={20} />} />
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center text-sm text-gray-500 mt-8">
          © {new Date().getFullYear()} Parmazing. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

// // Reusable Footer Link Component
// const FooterLink = ({ to, label }) => (
//   <Link to={to} className="text-gray-400 hover:text-yellow-500 transition text-sm">
//     {label}
//   </Link>
// );

// Reusable Social Media Icon Component
const SocialLink = ({ href, icon }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-yellow-500 transition">
    {icon}
  </a>
);

export default Footer;
