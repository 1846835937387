import React from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AboutMe from "./components/pages/AboutMe";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";
import Blog from "./components/pages/Blog";
import PostDisplay from "./components/display/BlogPostDisplay";
import { PostsProvider } from "./context/PostsContext";
import Paintings from "./components/pages/Paintings";
import PaintingsDisplay from "./components/display/PaintingsDisplay";
import Home from "./components/pages/Home";
// import Quizzes from "./components/pages/Quizzes";
// import QuizDisplay from "./components/display/QuizDisplay";
import Photos from "./components/pages/Photos";
// import Quotes from "./components/archive/Quotes";
import ContactMe from "./components/pages/ContactMe";

const App = () => {
  
  return (
    <PostsProvider>
      <Router>
        <div className="flex flex-col min-h-screen bg-gray-100">
          <Navbar />
          <div className="flex-1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/aboutme" element={<AboutMe />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/paintings" element={<Paintings />} />
              {/* <Route path="/quizzes" element={<Quizzes />} /> */}
              {/* <Route path="/quiz/:slug" element={<QuizDisplay />} /> */}
              {/* <Route path="/quotes" element={<Quotes />} /> */}
              <Route path="/contactme" element={<ContactMe/>}/>
              <Route path="/photos" element={<Photos />} />
              <Route path="/post/:slug" element={<PostDisplay />} />
              <Route path="/:slug" element={<PaintingsDisplay />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </PostsProvider>
  );
};

export default App;
