import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://parmazing.net/wp-json/wp/v2/posts?per_page=3")
      .then((res) => res.json())
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch((err) => console.error("Error fetching posts:", err));
  }, []);

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-start bg-gray-100 overflow-hidden pt-32 pb-32">
      
      {/* Background Squares */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-72 h-72 bg-yellow-200 top-[-50px] left-[-50px] rotate-6 rounded-lg"></div>
        <div className="absolute w-64 h-64 bg-yellow-300 bottom-[-50px] right-[-50px] -rotate-6 rounded-lg"></div>
        <div className="absolute w-56 h-56 bg-yellow-100 top-1/3 left-1/4 -translate-x-1/2 -translate-y-1/2 rotate-3 rounded-lg"></div>
        <div className="absolute w-80 h-80 bg-yellow-400 bottom-1/4 right-1/3 translate-x-1/2 translate-y-1/2 -rotate-12 rounded-lg"></div>
      </div>

      {/* Main Content */}
      <div className="relative z-10 flex flex-col md:flex-row items-center justify-between max-w-6xl mx-auto px-6 md:px-12">
        
        {/* Text Section */}
        <div className="max-w-lg text-left">
          <h1 className="text-6xl md:text-7xl font-extrabold text-gray-900 leading-tight">
            Breathing in the aroma of <span className="text-yellow-600">creativity.</span>
          </h1>
          <p className="text-gray-700 text-lg mt-4">
            Exploring the art of digital storytelling through visuals, words, and moments.
          </p>

          <Link
            to="/aboutme"
            className="inline-block mt-6 px-8 py-4 bg-gray-900 text-white text-lg font-semibold rounded-md hover:bg-yellow-600 transition duration-300"
          >
            Learn More
          </Link>
        </div>

        {/* Image Section */}
        <div className="relative w-96 h-96 flex justify-center items-center">
          <img
            src="https://parmazing.net/wp-content/uploads/img_1482.jpg"
            alt="Creative Person"
            className="w-full h-full object-cover rounded-lg shadow-lg transform hover:scale-105 transition duration-300"
          />
        </div>
      </div>

      {/* Latest Blog Posts Section */}
      <section className="relative z-10 w-full max-w-5xl mx-auto mt-20 px-6">
        <h2 className="text-4xl font-bold text-center text-gray-800">Latest Posts</h2>
        <div className="w-20 h-1 bg-yellow-500 mx-auto mt-2 rounded-full"></div>

        {loading ? (
          <p className="text-center text-gray-600 mt-6">Loading latest posts...</p>
        ) : (
          <div className="mt-12 grid gap-8 md:grid-cols-3">
            {posts.map((post) => (
              <div
                key={post.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300"
              >
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900">{post.title.rendered}</h3>
                  <p className="text-gray-600 mt-2 text-sm">
                    {post.excerpt.rendered.replace(/<[^>]+>/g, "").slice(0, 100)}...
                  </p>
                  <Link
                    to={`/post/${post.id}`}
                    className="inline-block mt-4 text-yellow-600 font-semibold hover:underline"
                  >
                    Read More →
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default Home;
