import React, { useEffect, useState } from "react";

const Photos = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://parmazing.net/wp-json/wp/v2/pages?slug=my-photos&_embed")
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0) {
          const content = data[0].content.rendered;
          const parser = new DOMParser();
          const doc = parser.parseFromString(content, "text/html");
          const images = Array.from(doc.querySelectorAll("img")).map((img) => ({
            src: img.src,
            alt: img.alt || "Photo",
          }));
          setPhotos(images);
        } else {
          setError("No photos found.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen pt-24">
        <p className="text-yellow-600 font-semibold animate-pulse text-lg">
          Loading...
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen pt-24">
        <p className="text-red-600 font-semibold text-lg">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center px-6 pt-32 pb-16 overflow-hidden">
      {/* Background Shapes */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="w-3/5 h-3/5 bg-yellow-100 absolute top-0 left-0 transform -translate-x-1/4 -translate-y-1/4 rounded-full opacity-50"></div>
        <div className="w-2/5 h-2/5 bg-yellow-200 absolute bottom-0 right-0 transform translate-x-[25%] translate-y-1/4 rounded-full opacity-50"></div>
      </div>

      {/* Header */}
      <header className="relative z-10 text-center mb-12">
        <h1 className="text-5xl font-extrabold text-gray-900">Photos</h1>
        <p className="text-lg text-gray-700 mt-2">
          Capturing moments, one shot at a time.
        </p>
        <div className="w-24 h-1 bg-yellow-500 mx-auto mt-2 rounded-full"></div>
      </header>

      {/* 3-Column Grid Layout */}
      {photos.length === 0 ? (
        <p className="text-gray-500 text-center">No photos available.</p>
      ) : (
        <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 max-w-5xl">
          {photos.map((photo, index) => (
            <div
              key={index}
              className="shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl"
            >
              <img
                src={photo.src}
                alt={photo.alt}
                className="w-full object-cover rounded-lg"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Photos;
