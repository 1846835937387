import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../common/Pagination";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 9;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const excludedCategories = ["Paintings", "Quiz", "Quotes", "Poll"];

  useEffect(() => {
    fetch("https://parmazing.net/wp-json/wp/v2/posts?per_page=100")
      .then((res) => res.json())
      .then((data) => {
        setPosts(data);
        return data;
      })
      .then((posts) => {
        const categoryIds = [...new Set(posts.flatMap((post) => post.categories))];
        return fetch(`https://parmazing.net/wp-json/wp/v2/categories?include=${categoryIds.join(",")}`);
      })
      .then((res) => res.json())
      .then((data) => {
        const categoryMap = {};
        data.forEach((category) => {
          categoryMap[category.id] = category.name;
        });
        setCategories(categoryMap);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-yellow-600 font-semibold animate-pulse text-lg">
          Loading...
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-600 font-semibold text-lg">Error: {error}</p>
      </div>
    );
  }

  const categorizedPosts = {};
  posts.forEach((post) => {
    post.categories.forEach((categoryId) => {
      const categoryName = categories[categoryId] || "Uncategorized";
      if (!excludedCategories.includes(categoryName)) {
        if (!categorizedPosts[categoryName]) {
          categorizedPosts[categoryName] = [];
        }
        categorizedPosts[categoryName].push(post);
      }
    });
  });

  const availableCategories = Object.keys(categorizedPosts);

  const displayedPosts =
    selectedCategory === "All"
      ? posts.filter((post) =>
          post.categories.some(
            (categoryId) => !excludedCategories.includes(categories[categoryId])
          )
        )
      : categorizedPosts[selectedCategory] || [];

  const totalPages = Math.ceil(displayedPosts.length / postsPerPage);
  const paginatedPosts = displayedPosts.slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  );

  return (
    <div className="relative min-h-screen flex flex-col items-center px-6 md:px-12 pt-32 pb-20 bg-gray-100">
      {/* Background Accents */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="w-96 h-96 bg-yellow-200 opacity-20 rounded-full absolute top-10 left-10 transform -translate-x-1/3 -translate-y-1/3"></div>
        <div className="w-72 h-72 bg-yellow-100 opacity-20 rounded-full absolute bottom-10 right-10 transform translate-x-1/3 translate-y-1/3"></div>
      </div>

      {/* Header */}
      <header className="relative z-10 text-center">
        <h1 className="text-5xl font-extrabold text-gray-900 leading-tight">
          Discover <span className="text-yellow-500">Stories</span> & Insights
        </h1>
        <p className="text-lg text-gray-700 mt-4">
          A curated collection of thoughts, ideas, and reflections.
        </p>
      </header>

      {/* Category Filter Bar */}
      <div className="relative z-10 flex flex-wrap justify-center gap-4 mt-8">
        <button
          className={`px-6 py-3 rounded-full text-sm font-medium transition ${
            selectedCategory === "All"
              ? "bg-gray-800 text-white"
              : "bg-gray-300 text-gray-800 hover:bg-gray-400"
          }`}
          onClick={() => {
            setSelectedCategory("All");
            setCurrentPage(1);
          }}
        >
          All
        </button>
        {availableCategories.map((category) => (
          <button
            key={category}
            className={`px-6 py-3 rounded-full text-sm font-medium transition ${
              selectedCategory === category
                ? "bg-gray-800 text-white"
                : "bg-gray-300 text-gray-800 hover:bg-gray-400"
            }`}
            onClick={() => {
              setSelectedCategory(category);
              setCurrentPage(1);
            }}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Blog Posts Grid */}
      <div className="relative z-10 grid md:grid-cols-2 lg:grid-cols-3 gap-10 mt-12 max-w-6xl">
        {paginatedPosts.map((post) => {
          const imageUrl =
            post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || "";
          return (
            <div
              key={post.id}
              className="bg-white p-6 rounded-3xl shadow-md border border-gray-300 flex flex-col justify-between transform hover:scale-105 transition duration-300"
            >
              {/* Post Image */}
              {imageUrl && (
                <div className="overflow-hidden rounded-lg mb-4">
                  <img
                    src={imageUrl}
                    alt={post.title.rendered}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                </div>
              )}

              {/* Post Title */}
              <h2 className="text-2xl font-bold text-gray-900 mb-3">
                <Link
                  to={`/post/${post.slug}`}
                  className="hover:text-yellow-600 transition duration-200"
                >
                  {post.title.rendered}
                </Link>
              </h2>

              {/* Excerpt */}
              <div
                className="text-gray-700 line-clamp-3"
                dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
              />

              {/* Read More Button */}
              <div className="mt-4">
                <Link
                  to={`/post/${post.slug}`}
                  className="inline-block px-4 py-2 bg-yellow-500 text-white font-medium rounded-md hover:bg-yellow-600 transition duration-300"
                >
                  Read More →
                </Link>
              </div>
            </div>
          );
        })}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          className="relative z-10 mt-12"
        />
      )}
    </div>
  );
};

export default Blog;
