import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, X } from "react-feather";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (
    <nav className="absolute top-0 left-0 right-0 z-50 px-10 py-6 flex justify-between items-center bg-transparent">
      {/* Integrated Branding (Parmazing) */}
      <Link to="/" className="text-5xl font-extrabold text-gray-900 tracking-wide">
        <span className="text-yellow-600">parmazing.</span>
      </Link>

      {/* Desktop Menu */}
      <div className="hidden md:flex space-x-10 text-lg font-medium">
        {/* <NavLink to="/" label="Home" location={location} /> */}
        <NavLink to="/aboutme" label="About" location={location} />
        <NavLink to="/blog" label="Latest Work" location={location} />
        <NavLink to="/contactme" label="Get in Touch" location={location} />
      </div>

      {/* Mobile Menu Button */}
      <button className="md:hidden text-gray-900" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <X size={32} /> : <Menu size={32} />}
      </button>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 bg-white flex flex-col items-center justify-center transform transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } md:hidden`}
      >
        <button className="absolute top-6 right-6 text-gray-900" onClick={() => setIsOpen(false)}>
          <X size={36} />
        </button>

        {/* <NavLink to="/" label="Home" mobile location={location} setIsOpen={setIsOpen} /> */}
        <NavLink to="/aboutme" label="About" mobile location={location} setIsOpen={setIsOpen} />
        <NavLink to="/blog" label="Latest Work" mobile location={location} setIsOpen={setIsOpen} />
        <NavLink to="/contactme" label="Get in Touch" mobile location={location} setIsOpen={setIsOpen} />
      </div>
    </nav>
  );
};

// Reusable NavLink Component
const NavLink = ({ to, label, mobile, location, setIsOpen }) => (
  <Link
    to={to}
    onClick={() => mobile && setIsOpen(false)}
    className={`text-lg font-semibold tracking-wide ${
      location.pathname === to ? "text-yellow-600 underline" : "text-gray-900 hover:text-yellow-600"
    } transition duration-200 ${mobile ? "py-3 text-2xl" : ""}`}
  >
    {label}
  </Link>
);

export default Navbar;
