import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Paintings = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const paintingsCategoryId = 154;

  useEffect(() => {
    fetch(`https://parmazing.net/wp-json/wp/v2/posts?categories=${paintingsCategoryId}&_embed&per_page=100`)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setPosts(data);
        } else {
          setError("Invalid response from server.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen pt-24">
        <p className="text-yellow-600 font-semibold animate-pulse text-lg">
          Loading...
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen pt-24">
        <p className="text-red-600 font-semibold text-lg">
          ❌ Error: {error}
        </p>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center px-6 pt-32 pb-16 overflow-hidden">
      {/* Background Shapes */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="w-3/5 h-3/5 bg-yellow-100 absolute top-0 left-0 transform -translate-x-1/4 -translate-y-1/4 rounded-full opacity-50"></div>
        <div className="w-2/5 h-2/5 bg-yellow-200 absolute bottom-0 right-0 transform translate-x-[25%] translate-y-1/4 rounded-full opacity-50"></div>
      </div>

      {/* Header */}
      <header className="relative z-10 text-center mb-12">
        <h1 className="text-5xl font-extrabold text-gray-900">Paintings</h1>
        <p className="text-lg text-gray-700 mt-2">
          Explore beautiful artwork, created with passion.
        </p>
        <div className="w-24 h-1 bg-yellow-500 mx-auto mt-2 rounded-full"></div>
      </header>

      {/* Masonry Grid */}
      {posts.length === 0 ? (
        <p className="text-gray-500 text-center">No paintings found.</p>
      ) : (
        <div className="relative z-10 columns-1 sm:columns-2 md:columns-3 gap-6 space-y-6 max-w-5xl">
          {posts.map((post) => {
            const imageUrl = post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || "";
            const fallbackImage = "https://via.placeholder.com/400"; // Placeholder if no image

            return (
              <Link
                key={post.id}
                to={`/${post.slug}`}
                className="block break-inside-avoid shadow-md hover:shadow-xl transition duration-300 transform hover:scale-105 relative group"
              >
                <div className="relative overflow-hidden rounded-lg">
                  {/* Painting Image */}
                  <img
                    src={imageUrl || fallbackImage}
                    alt={post.title.rendered}
                    className="w-full rounded-lg transition-transform duration-500 ease-in-out group-hover:scale-110"
                    loading="lazy"
                  />

                  {/* Dark Overlay */}
                  <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300 flex items-center justify-center">
                    <p className="text-white text-lg font-semibold opacity-0 group-hover:opacity-100 transition duration-300 text-center w-full">
                      {post.title.rendered}
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Paintings;
