import { createContext, useEffect, useState } from "react";

export const PostsContext = createContext();

export const PostsProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cachedPosts = sessionStorage.getItem("posts");
    if (cachedPosts) {
      setPosts(JSON.parse(cachedPosts));
      setLoading(false);
    } else {
      fetch("https://parmazing.net/wp-json/wp/v2/posts")
        .then((res) => res.json())
        .then((data) => {
          setPosts(data);
          sessionStorage.setItem("posts", JSON.stringify(data));
          setLoading(false);
        })
        .catch((error) => console.error("Error fetching posts:", error));
    }
  }, []);

  return (
    <PostsContext.Provider value={{ posts, loading }}>
      {children}
    </PostsContext.Provider>
  );
};
