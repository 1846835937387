import { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { PostsContext } from "../../context/PostsContext";

const PostDisplay = () => {
  const { slug } = useParams();
  const { posts, loading } = useContext(PostsContext);
  const [post, setPost] = useState(null);
  const [prevPost, setPrevPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);

  useEffect(() => {
    if (!loading) {
      const index = posts.findIndex((p) => p.slug === slug);
      if (index !== -1) {
        setPost(posts[index]);
        setPrevPost(posts[index - 1] || null);
        setNextPost(posts[index + 1] || null);
      } else {
        setPost(null);
      }
    }
  }, [loading, slug, posts]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen pt-24">
        <p className="text-yellow-600 font-semibold animate-pulse text-lg">
          Loading...
        </p>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-center px-6">
        <div className="bg-red-100 border border-red-400 text-red-700 p-6 rounded-lg shadow-md w-full max-w-lg">
          <h2 className="text-2xl font-bold">Post Not Found</h2>
          <p className="text-gray-600 mt-2">
            The post you're looking for doesn't exist or may have been removed.
          </p>
          <Link
            to="/blog"
            className="mt-4 inline-block text-yellow-600 font-semibold hover:text-yellow-700 transition"
          >
            ← Back to Blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center px-6 pt-32 pb-16 overflow-hidden">
      {/* Background Shapes */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="w-2/5 h-2/5 bg-yellow-100 absolute top-0 left-0 transform -translate-x-1/4 -translate-y-1/4 rounded-full opacity-50"></div>
        <div className="w-3/5 h-3/5 bg-yellow-200 absolute bottom-0 right-0 transform translate-x-[25%] translate-y-1/4 rounded-full opacity-50"></div>
      </div>

      {/* Post Content Wrapper */}
      <div className="relative z-10 max-w-3xl w-full bg-white shadow-lg rounded-lg p-8">
        {/* Post Header */}
        <header className="text-center">
          <h1 className="text-5xl font-extrabold text-gray-900 leading-tight">
            {post.title.rendered}
          </h1>
          <div className="w-24 h-1 bg-yellow-500 mx-auto mt-3 rounded-full"></div>
          <p className="text-gray-500 text-sm mt-4">
            Published on {new Date(post.date).toDateString()}
          </p>
        </header>

        {/* Post Content */}
        <article className="prose prose-lg text-gray-800 leading-relaxed mt-8">
          <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </article>

        {/* Navigation Buttons */}
        <div className="mt-12 flex justify-between">
          {/* Previous Post */}
          {prevPost ? (
            <Link
              to={`/post/${prevPost.slug}`}
              className="px-6 py-3 text-gray-700 bg-gray-200 hover:bg-gray-300 font-medium rounded-lg shadow-md transition transform hover:-translate-x-1"
            >
              ← {prevPost.title.rendered}
            </Link>
          ) : (
            <span className="px-6 py-3 text-gray-400 bg-gray-200 font-medium rounded-lg shadow-md opacity-50 cursor-not-allowed">
              ← No Previous Post
            </span>
          )}

          {/* Next Post */}
          {nextPost ? (
            <Link
              to={`/post/${nextPost.slug}`}
              className="px-6 py-3 text-gray-700 bg-gray-200 hover:bg-gray-300 font-medium rounded-lg shadow-md transition transform hover:translate-x-1"
            >
              {nextPost.title.rendered} →
            </Link>
          ) : (
            <span className="px-6 py-3 text-gray-400 bg-gray-200 font-medium rounded-lg shadow-md opacity-50 cursor-not-allowed">
              No Next Post →
            </span>
          )}
        </div>

        {/* Back to Blog */}
        <div className="mt-12 text-center">
          <Link
            to="/blog"
            className="px-6 py-3 text-white bg-yellow-500 hover:bg-yellow-600 font-medium rounded-full shadow-md transition-all transform hover:scale-105"
          >
            ← Back to Blog
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostDisplay;
