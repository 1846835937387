import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const PaintingsDisplay = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://parmazing.net/wp-json/wp/v2/posts?slug=${slug}&_embed`)
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0) {
          setPost(data[0]);
        } else {
          setError("Painting not found.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-yellow-600 font-semibold animate-pulse text-lg">
          Loading...
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-600 font-semibold text-lg">
          Error: {error}
        </p>
      </div>
    );
  }

  const featuredImage = post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || "";
  const formattedDate = new Date(post.date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // Move the featured image inside the content
  let modifiedContent = post.content.rendered;
  if (featuredImage) {
    modifiedContent = `<img src="${featuredImage}" class="rounded-lg shadow-lg max-w-lg h-auto border border-gray-300 mx-auto my-6" alt="${post.title.rendered}" />` + modifiedContent;
  }

  return (
    <div className="max-w-4xl mx-auto px-6 py-16">
      {/* Title Section */}
      <h1 className="text-5xl font-extrabold text-gray-900 text-center">
        {post.title.rendered}
      </h1>
      <p className="text-gray-500 text-lg text-center mt-2">{formattedDate}</p>
      <div className="w-20 h-1 bg-yellow-500 mx-auto mt-3 rounded-full"></div>

      {/* Painting Description with Moved Featured Image */}
      <div
        className="prose prose-lg text-gray-700 mt-10 text-center leading-relaxed"
        dangerouslySetInnerHTML={{ __html: modifiedContent }}
      />

      {/* Back Button */}
      <div className="mt-12 text-center">
        <a
          href="/paintings"
          className="px-6 py-3 text-white bg-yellow-500 hover:bg-yellow-600 font-medium rounded-full shadow-md transition duration-200 inline-block"
        >
          ← Back to Paintings
        </a>
      </div>
    </div>
  );
};

export default PaintingsDisplay;
