import React, { useState } from "react";

const ContactMe = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Simple form validation
    if (!formData.name || !formData.email || !formData.message) {
      setStatus({ type: "error", message: "All fields are required!" });
      return;
    }

    // Simulate successful form submission
    setTimeout(() => {
      setStatus({ type: "success", message: "Message sent successfully!" });
      setFormData({ name: "", email: "", message: "" });
    }, 1000);
  };

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center px-6 md:px-12 pt-32 pb-20 bg-gray-100">
      {/* Background Accents */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="w-96 h-96 bg-yellow-200 opacity-20 rounded-full absolute top-10 left-10 transform -translate-x-1/3 -translate-y-1/3"></div>
        <div className="w-72 h-72 bg-yellow-100 opacity-20 rounded-full absolute bottom-10 right-10 transform translate-x-1/3 translate-y-1/3"></div>
      </div>

      {/* Header */}
      <header className="relative z-10 text-center">
        <h1 className="text-5xl font-extrabold text-gray-900 leading-tight">
          Let's <span className="text-yellow-500">Connect</span>
        </h1>
        <p className="text-lg text-gray-700 mt-4">
          Have a question, project, or just want to say hi? Drop me a message!
        </p>
        <div className="w-16 h-1 bg-yellow-500 mx-auto mt-2 rounded-full"></div>
      </header>

      {/* Contact Form */}
      <div className="relative z-10 bg-white p-8 mt-10 rounded-3xl shadow-md max-w-2xl w-full">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 font-medium">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg mt-1 focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              placeholder="Enter your name"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg mt-1 focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              placeholder="Enter your email"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg mt-1 focus:ring-2 focus:ring-yellow-500 focus:outline-none"
              placeholder="Write your message here..."
              rows="5"
            ></textarea>
          </div>

          {/* Status Message */}
          {status && (
            <p
              className={`text-center font-semibold transition-opacity duration-300 ${
                status.type === "success" ? "text-green-600" : "text-red-600"
              }`}
            >
              {status.message}
            </p>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-yellow-500 text-white py-3 rounded-lg font-semibold text-lg shadow-md transform hover:scale-105 transition duration-300 hover:bg-yellow-600"
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactMe;
